@import 'global.scss';

.root {
  width: 310px;
  height: 444px;
  position: relative;
  border-radius: $corner-radius-large;
  background: linear-gradient(90deg, #1a02ba 0%, #820eb9 150%, #1a02ba 100%);
  cursor: pointer;
  overflow: hidden;
  z-index: 5;

  &::after,
  &::before {
    @include white-bg-circle;
    transition: all 0.3s ease-in-out;
  }
  &::before {
    top: -100px;
    right: -75px;
  }
  &::after {
    top: 185px;
    left: -110px;
  }

  &:hover {
    border-radius: 24px 24px 24px 0;
    transition: height 0.3s ease-in-out;
    &::before {
      top: -100%;
      right: -100%;
    }
    &::after {
      top: 100%;
      left: -100%;
    }
    @include desktop-max {
      height: 484px;
      box-shadow: 0px 20px 20px 0px rgba(52, 86, 251, 0.2);
    }

    .image__root {
      opacity: 1;
    }
    .buttons__more {
      background-color: $color-magenta-100;
      color: $color-base-0;
    }
  }

  &__homeTab {
    height: 568px;
    @include desktop-max {
      height: 568px;
    }
    &:hover {
      border-radius: 24px 24px 24px 0;

      @include desktop-max {
        height: 588px;
      }
    }
  }
}

.content {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.information {
  display: flex;
  flex-direction: column;
}

.title {
  @include heading-h4;
  color: $color-base-0;
  margin-bottom: 8px;

  &__mobileAdding {
    margin-bottom: 8px;
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.image {
  height: 100%;

  &__root {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    object-fit: cover;
    background-size: cover;
  }
  &__figure {
    height: 100%;
  }
  &__wrapper {
    height: 100%;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  &__more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 13px 24px;
    border-radius: 12px;
    background: $color-base-0;
    color: $color-base-900;
    @include label-l4-m;

    &:hover,
    &:active {
      background-color: $color-magenta-100;
      color: $color-base-0;
    }
  }
}

.button {
  &__root {
    padding: 0;
    margin: 0;
    background-color: $color-base-0;
    color: $color-black;
    @include label-l4-m;
    border-radius: $other-input;
    border: none;

    &:active,
    &:focus {
      background-color: $color-base-0;
      border: none;
      outline: none;
    }

    &:hover {
      background-color: $color-base-200;
    }
  }

  &__added {
    background-color: $color-magenta-100;
    color: $color-base-0;

    &:active,
    &:focus {
      background-color: $color-magenta-100;
    }

    &:hover {
      background-color: $color-magenta-100;
    }
  }

  &__withIcon {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__about {
    background-color: transparent;
    color: $color-base-0;
    border: 1px solid $color-base-0;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border: 1px solid $color-base-0;
    }
  }
}

.price {
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__content {
    display: flex;
    gap: 12px;
    align-items: center;
    height: 36px;
  }

  &__old {
    display: flex;
    gap: 2px;
    align-items: center;

    span {
      font-family: Roboto;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: $color-base-0;
      text-decoration: line-through;
    }
  }

  &__actual {
    display: flex;
    align-items: center;
    gap: 4px;

    .price__number {
      @include heading-h3;
    }
    span {
      @include label-l4;
      color: $color-base-0;
    }
  }

  &__from {
    margin-right: 8px;
  }

  &__promotion {
    color: $color-base-0;
    @include label-l6;

    &__landing {
      position: absolute;
      top: -12px;
      right: 0;
      padding: 8px 20px;
      border-radius: 0 12px;
      background-color: $color-magenta-100;
      @include label-l3-m;
    }
  }
}

.checkbox {
  position: absolute;
  top: 20px;
  right: 20px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: transparent;
  color: $color-base-0;
  border: 1px solid $color-base-0;
  border-radius: 12px;
  @include label-l4-m;
}

.promotionTag{
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 20px;
  border-radius: 0 12px 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-magenta-100;

  color: $color-base-0;
  @include label-l3-m;
}