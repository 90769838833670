@import 'media-queries';
@import 'variables';
@import 'fonts';
@import 'typography';
@import 'swiper';
@import 'markdown';

@mixin white-bg-circle {
  content: '';
  position: absolute;
  width: 370px;
  height: 370px;
  border-radius: 370px;
  background: rgba(254, 254, 254, 0.1);
}

@mixin pink-bg-circle {
  @include white-bg-circle;
  background: linear-gradient(205deg, rgba(235, 0, 140, 0.15) 32.06%, rgba(255, 74, 74, 0) 86.38%);
}

@mixin purple-bg-circle {
  @include white-bg-circle;
  background: linear-gradient(42deg, rgba(27, 20, 136, 0.15) 35.6%, rgba(103, 1, 169, 0) 87.33%);
}

@mixin scrollbar {
  &__bar {
    &[data-orientation='horizontal'] {
      display: none;
    }
    opacity: 0.5;
    &:hover {
      opacity: 0.7;
      .scroll__thumb {
        background-color: $color-base-400 !important;
      }
    }
  }
  &__thumb {
    background-color: $color-base-400;
  }
}

@mixin scrollbar-horizontal {
  @include scrollbar;
  &__bar {
    &[data-orientation='horizontal'] {
      height: 8.5px;
      display: flex;
    }
  }
  &__viewport {
    padding-right: 0;
    @include tablet {
      padding: 0;
    }
  }
}

@mixin headings {
  @include heading-h4;
  color: $color-neutral-gray;
  margin: 0;
  padding: 0;
  @include tablet {
    @include heading-h2;
  }
  &__diy {
    @include heading-h4;
  }
}

@mixin disabled-block {
  pointer-events: none;
  filter: grayscale(100%) opacity(70%);
}

@mixin tabs-animation {
  opacity: 0;
  animation: show 0.2s 1;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes skeletonAnimation {
  from,
  to {
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }
}